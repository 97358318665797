import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast, ToastContainer, Slide } from 'react-toastify';
import useNotificationPermission from 'hooks/useNotificationPermission';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Notifications.module.scss';

function Notifications({ notifications = [], removeByUid, navigate }) {
  useNotificationPermission();

  const displayNotificationText = (type, text, data) => (
    <div className={styles.notification} data-cy="notification-alert">
      {data ? (
        <div className={styles.wrapper}>
          <span className={styles.text}>{text}</span>
          <span
            className="spiro-link bold-500"
            onClick={() => navigate(`/contacts/${data.id}`)}
            role="button"
            tabIndex="0"
          >
            {data.first_name} {data.last_name}
          </span>
        </div>
      ) : (
        <span className={styles.span}>{text}</span>
      )}
    </div>
  );

  const removeNotification = (uid) => {
    removeByUid(uid);
  };

  const drawNotifications = (data) => {
    data.forEach((n) => {
      toast[n.type](displayNotificationText(n.type, n.text, n.data), {
        toastId: n.uid,
        style: {
          width: '380px',
        },
        onClose: () => {
          removeNotification(n.uid);
        },
        autoClose: 4000,
        position: 'top-center',
        closeButton: true,
      });
    });
  };

  useEffect(() => {
    if (notifications.length) {
      drawNotifications(notifications);
    }
  }, [notifications]);

  return (
    <ToastContainer
      data-cy="notification-message"
      transition={Slide}
      newestOnTop
      style={{ top: '75px' }}
    />
  );
}

Notifications.propTypes = {
  removeByUid: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default Notifications;
