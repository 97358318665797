import { serializeFiltersToString } from 'helpers/filters';
import { getDataCollectorsImportHistory } from 'services/data-collectors';
import { forEachError } from '../../../../helpers/errorHelper';
import {
  SET_DRIVE_ACCESS_TOKEN,
  SET_DRIVE_RESPONSE,
  SET_CSV_HEADERS,
  SET_ROW_COUNT,
  SET_RESOURCE_FIELDS,
  SET_DISABLED_FORM_SUBMIT,
  SET_LOADING,
  SET_SELECTED_FILE,
  SET_CAMPAIGN_VALUE,
  SET_OPPORTUNITY_VALUE,
  SET_CONTACT_RESOURCE,
  CLEAR_IMPORT_STATE,
  SET_CUSTOM_ENTITY,
  SET_SELECTED_ITEMS,
  SHOW_IMPORT_DELETE_MODAL,
  SET_PREVIOUS_PATH,
  SET_CURRENT_PATH,
  SET_MAPPED_VALUE,
  REQUEST_SUCCESS,
  SET_IMPORT_RESOURCE,
  RESET_IMPORT_DATA,
  RESET_SELECTED_ITEMS,
  SET_COMPONENT_LOADING,
  SET_META,
  SET_DOWNLOAD_LOADING,
  SET_IMPORTS_HISTORY,
  SET_LOAD_MORE_HISTORY,
  CLEAR_IMPORTS_HISTORY,
  SET_DISABLED_BROWSE,
  SET_UPLOAD_PROCESS,
  SET_DUPLICATES_DOWNLOAD_LOADING,
  SET_FILE_NAME,
  SET_EXTERNAL_ID,
  SET_KEY_FOR_EXTERNAL_ID,
} from './constants';
import {
  csvHeaders as getCsvHeaders,
  resourceFields as getResourceFields,
  getDriveAccessToken,
  getImportHistory,
} from '../../../services/imports';
import { getContacts as getContactsRemote } from '../../../services/contacts';
import { getAll as getAllAccounts } from '../../../services/accounts';
import { getOpportunities as getAllOpportunities } from '../../../services/opportunities';
import { getCustomObjects as getAllCustomObjects } from '../../../services/custom-objects';
import ContactRole from '../../../models/ContactRole';
import { parseMetaFromResponse } from '../../../helpers/meta';
import { getExportLinksForLargeFiles, handleDownload, exportLargeFile } from '../gDriveDownload';
import { downloadFile } from '../helpers';
import { error as errorAlert } from '../../../state/notifications/actions';
import { parseImportsData } from '../../../helpers/import';

export const setDisabledBrowse = (payload) => ({
  type: SET_DISABLED_BROWSE,
  payload,
});

export const setUploadProcess = (payload) => ({
  type: SET_UPLOAD_PROCESS,
  payload,
});

export const setMeta = (payload) => ({
  type: SET_META,
  payload,
});

export const setLoadMoreHistory = (payload) => ({
  type: SET_LOAD_MORE_HISTORY,
  payload,
});

export const fetchDriveAccessToken = () => async (dispatch) => {
  try {
    const { access_token: accessToken } = await getDriveAccessToken();
    dispatch({ type: SET_DRIVE_ACCESS_TOKEN, payload: accessToken });
  } catch (e) {
    console.error(e);
  }
};

export const setImportsHistory = (payload) => ({
  type: SET_IMPORTS_HISTORY,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const fetchHistoryData =
  (params, initialFetchingLoader, isDataCollectorScreen) => async (dispatch) => {
    try {
      if (initialFetchingLoader) dispatch(setLoading(true));
      const res = isDataCollectorScreen
        ? await getDataCollectorsImportHistory(params)
        : await getImportHistory(params);
      if (initialFetchingLoader) dispatch(setLoading(false));
      const newImports = [...parseImportsData(res.imports || res.data_collector_imports)];
      dispatch(setImportsHistory(newImports));
      dispatch(setMeta(res.meta));
      dispatch(setLoadMoreHistory(false));
    } catch (e) {
      forEachError(e.data, (err) => dispatch(errorAlert(err)));
    }
  };

export const clearImportsHistory = () => ({
  type: CLEAR_IMPORTS_HISTORY,
});

export const setDriveResponse = (payload) => ({
  type: SET_DRIVE_RESPONSE,
  payload,
});

export const setImportRowCount = (payload) => ({
  type: SET_ROW_COUNT,
  payload,
});

export const fetchCsvHeaders = (s3FilePath) => async (dispatch) => {
  try {
    const response = await getCsvHeaders({
      csv: {
        csv_name: s3FilePath,
      },
    });
    dispatch({ type: SET_CSV_HEADERS, payload: response.headers });
  } catch (e) {
    console.error(e);
  }
};

export const fetchResourceFields = (resource, type) => async (dispatch) => {
  try {
    const resFields = await getResourceFields({ resource, type });
    dispatch({ type: SET_RESOURCE_FIELDS, payload: resFields });
  } catch (e) {
    console.error(e);
  }
};

export const setResourceFields = (payload) => ({
  type: SET_RESOURCE_FIELDS,
  payload,
});

export const setDisabledFormSubmit = (payload) => ({
  type: SET_DISABLED_FORM_SUBMIT,
  payload,
});

export const setDownloadLoading = (payload) => ({
  type: SET_DOWNLOAD_LOADING,
  payload,
});

export const setDuplicatesDownloadLoading = (payload) => ({
  type: SET_DUPLICATES_DOWNLOAD_LOADING,
  payload,
});

export const setSelectedFile = (payload) => ({
  type: SET_SELECTED_FILE,
  payload,
});

export const setFileName = (payload) => ({
  type: SET_FILE_NAME,
  payload,
});

export const setCampaignValue = (payload) => ({
  type: SET_CAMPAIGN_VALUE,
  payload,
});

export const setOpportunityValue = (payload) => ({
  type: SET_OPPORTUNITY_VALUE,
  payload,
});

export const setContactResource = (payload) => ({
  type: SET_CONTACT_RESOURCE,
  payload,
});

export const setCustomEntity = (payload) => ({
  type: SET_CUSTOM_ENTITY,
  payload,
});

export const clearImportState = () => ({
  type: CLEAR_IMPORT_STATE,
});

export const setSelectedItems = (payload) => ({
  type: SET_SELECTED_ITEMS,
  payload,
});

export const showImportDeleteModal = (payload) => ({
  type: SHOW_IMPORT_DELETE_MODAL,
  payload,
});

export const setMappedValue = (payload) => ({
  type: SET_MAPPED_VALUE,
  payload,
});

export const setExternalId = (payload) => ({
  type: SET_EXTERNAL_ID,
  payload,
});

export const setKeyForExternalId = (payload) => ({
  type: SET_KEY_FOR_EXTERNAL_ID,
  payload,
});

export const setComponentLoader = (payload) => ({
  type: SET_COMPONENT_LOADING,
  payload,
});

export const setImportPaths = (payload) => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: SET_PREVIOUS_PATH, payload: state.import.currentPath });
  dispatch({ type: SET_CURRENT_PATH, payload });
};

export const requestSuccess = (json, resource) => ({
  type: REQUEST_SUCCESS,
  data:
    resource === 'contacts'
      ? json.contacts.map((contact) => new ContactRole({ contact }).contact)
      : json[resource],
  meta: parseMetaFromResponse(json.meta),
});

function getParamsFromState(state) {
  const params = state.import.params;
  const currentFilters = state.filters.import.currentFilters;

  return {
    ...params,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export function getContacts(params = {}) {
  return (dispatch, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };

    return getContactsRemote(payload)
      .then((json) => {
        const res = json.contacts.map((contact) => ({
          ...contact,
          owner: contact.user.full_name,
        }));
        const contacts = { contacts: res, meta: json.meta };
        dispatch(requestSuccess(contacts, 'contacts'));
        dispatch(setLoading(false));
        dispatch(setComponentLoader(false));
        return Promise.resolve(contacts);
      })
      .catch((err) => Promise.reject(err));
  };
}

export function getAccounts(params = {}) {
  return (dispatch, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };

    return getAllAccounts(payload)
      .then((json) => {
        dispatch(requestSuccess(json, 'accounts'));
        dispatch(setLoading(false));
        dispatch(setComponentLoader(false));
        return Promise.resolve(json);
      })
      .catch((err) => Promise.reject(err));
  };
}

export function getOpportunities(params = {}) {
  return (dispatch, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };

    return getAllOpportunities(payload)
      .then((json) => {
        dispatch(requestSuccess(json, 'opportunities'));
        dispatch(setLoading(false));
        dispatch(setComponentLoader(false));
        return Promise.resolve(json);
      })
      .catch((err) => Promise.reject(err));
  };
}

export function getCustomObjects(params = {}) {
  return (dispatch, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = {
      ...stateParams,
      ...params,
      custom_field_id: getState().import.customEntity.id,
    };

    return getAllCustomObjects(payload)
      .then((res) => {
        const response = res.custom_objects;
        response.forEach((co) => {
          const keys = Object.keys(co.custom);

          keys.forEach((key) => {
            if (co.custom[key] instanceof Object) {
              // eslint-disable-next-line no-param-reassign
              co.custom[key] = co.custom[key].label;
            }
          });
        });

        res.custom_objects.forEach((co) => {
          // eslint-disable-next-line no-param-reassign
          co.custom = { ...co.custom, resource_name: co.resource_name };
        });

        dispatch(requestSuccess(res, 'custom_objects'));
        dispatch(setLoading(false));
        dispatch(setComponentLoader(false));
        return Promise.resolve(res);
      })
      .catch((err) => Promise.reject(err));
  };
}

export const setImportResource = (resource) => ({
  type: SET_IMPORT_RESOURCE,
  resource,
});

export const resetImportData = () => ({
  type: RESET_IMPORT_DATA,
});

export const resetSelectedItems = () => ({
  type: RESET_SELECTED_ITEMS,
});

const setFileID = (column, selectedFile) => {
  if (column === 'errorFile') return { fileId: selectedFile.failedCSVid, fileType: 'Error' };
  if (column === 'updatedFile') return { fileId: selectedFile.updatedCSVid, fileType: 'Updated' };
  if (column === 'createdFile') return { fileId: selectedFile.createdCSVid, fileType: 'Created' };
  if (column === 'originalFileName') return { fileId: selectedFile.csvName, fileType: 'Original' };
  return null;
};

export const downloadImportFile =
  (importId, column, originalName = '') =>
  async (dispatch, getState) => {
    const state = getState();
    if (
      column === 'errorFile' ||
      column === 'createdFile' ||
      column === 'updatedFile' ||
      column === 'originalFileName'
    ) {
      dispatch(setDownloadLoading(true));
    } else {
      dispatch(setDuplicatesDownloadLoading(true));
    }
    const selectedImport = state.import.importsHistory.find((item) => item.id === importId);
    const { fileId, fileType } = setFileID(column, selectedImport);

    if (fileId.includes('s3.amazonaws')) {
      window.location.href = fileId;
      dispatch(setDownloadLoading(false));
      return;
    }

    try {
      await dispatch(fetchDriveAccessToken());

      const {
        import: { driveAccessToken },
      } = getState();

      try {
        const { data } = await handleDownload(fileId, driveAccessToken);
        const fileName =
          fileType === 'Original'
            ? originalName
            : `${fileType} file for ${selectedImport.entity} - ${selectedImport.dateTime}`;
        downloadFile(data, fileName, 'csv');
      } catch (error) {
        if (
          error.response.status === 403 &&
          error.response.data.error.message === 'This file is too large to be exported.'
        ) {
          const { data } = await getExportLinksForLargeFiles(fileId, driveAccessToken);
          const exportLargeFileResponse = await exportLargeFile(
            data.exportLinks['text/csv'],
            driveAccessToken
          );
          const fileName = `${fileType} file for ${selectedImport.type} - ${selectedImport.dateTime}`;
          downloadFile(exportLargeFileResponse.data, fileName, 'csv');
        } else {
          dispatch(errorAlert(error.response.data.error.message));
        }
      }

      if (
        column === 'errorFile' ||
        column === 'createdFile' ||
        column === 'updatedFile' ||
        column === 'originalFileName'
      ) {
        dispatch(setDownloadLoading(false));
      } else {
        dispatch(setDuplicatesDownloadLoading(false));
      }
    } catch (e) {
      dispatch(setDownloadLoading(false));
      dispatch(setDuplicatesDownloadLoading(false));
      if (e.status === 404) {
        dispatch(errorAlert('Error file is deleted'));
      }
    }
  };
