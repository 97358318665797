import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import Button from 'lib/Button';
import styles from './ErrorPage.module.scss';

function ErrorPage() {
  const getBack = () => {
    window.location.assign('/');
  };
  return (
    <Grid container justifyContent="center" className="text-center">
      <Grid item xs={12}>
        <Paper elevation={0} square className={styles.wrapper}>
          <MoodBadIcon fontSize="large" className={styles.icon} />
          <Typography variant="h6">Sorry, something went wrong.</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Brace yourself until we get this error fixed.
          </Typography>
          <Button onClick={getBack} variant="contained" color="primary" className={styles.button}>
            Return home
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ErrorPage;
