import PropTypes from 'prop-types';
import SmartProductsOnly from 'components/SmartProductsOnly';
import UnauthorizedAccess from '../UnauthorizedAccess';

function SmartProductsRoute({ children }) {
  return (
    <SmartProductsOnly
      fallback={<UnauthorizedAccess message="You don't have permission to visit this page." />}
    >
      {children}
    </SmartProductsOnly>
  );
}

SmartProductsRoute.propTypes = {
  children: PropTypes.any.isRequired,
};

export default SmartProductsRoute;
