import PropTypes from 'prop-types';
import AddonsOnly from 'components/AddonsOnly';
import UnauthorizedAccess from '../UnauthorizedAccess';

function AddonsRoute({ children, addonName }) {
  return (
    <AddonsOnly
      addonName={addonName}
      displayFallback
      fallback={<UnauthorizedAccess message="You don't have permission to visit this page." />}
    >
      {children}
    </AddonsOnly>
  );
}

AddonsRoute.propTypes = {
  children: PropTypes.any.isRequired,
  addonName: PropTypes.string.isRequired,
};

export default AddonsRoute;
