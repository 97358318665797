export function getPayload(formData, fileUrls) {
  const payload = formData;

  if (fileUrls.length) {
    payload.message_type = 'mms';
    payload.media_urls = fileUrls.map((url) => url.publicUrl);
  }

  return payload;
}
